<script setup lang="ts">
import useBevcoFetch from "~/composables/useBevcoFetch";
import formatPriceDKK from "../../helpers/FormatPriceDKK";

const {apiInstance} = useShopwareContext();
const {totalPrice, shippingTotal} = useCart();
const cartTotal = computed(() => {
    return totalPrice.value - shippingTotal.value;
});
const topBarSlide = ref<HTMLElement>();
const carouselViewport = ref<HTMLElement>();
const countdown = ref<string>("");

onMounted(async () => {
    useBevcoFetch(
        apiInstance,
        `/store-api/shippingconfig`,
        {
            method: "GET"
        }
    ).then((data) => {
        if (data) {
            const disabledDates = data.disabledDates ?? "";
            const validateDeadline = function (date: Date) {
                if (deadline.getHours() >= 21) {
                    return false;
                }
                if (date.getDay() == 6 || date.getDay() == 0) {
                    return false;
                }
                if (date.getDay() == 5 && deadline.getHours() >= 17) {
                    return false;
                }
                const formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
                return !disabledDates.includes(formattedDate);
            }

            const deadline = new Date();
            if (validateDeadline(deadline)) {
                if (deadline.getDay() == 5) {
                    deadline.setHours(17, 0, 0, 0);
                } else {
                    deadline.setHours(21, 0, 0, 0);
                }
                setInterval(function () {
                    const now = new Date();
                    const diff = Math.floor((deadline.getTime() - now.getTime()) / 1000);
                    if (diff <= 0) {
                        countdown.value = "";
                        return;
                    }
                    const hours = Math.floor(diff / 3600);
                    const minutes = Math.floor((diff % 3600) / 60);
                    const seconds = diff % 60;
                    countdown.value = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
                }, 1000);
            }
        }
    });
});

setInterval(function () {
    if (carouselViewport.value && topBarSlide.value) {
        const next = carouselViewport.value.scrollLeft + topBarSlide.value.clientWidth;
        if (next >= carouselViewport.value.scrollWidth) {
            carouselViewport.value.style.scrollBehavior = "auto";
            carouselViewport.value.scrollLeft = 0;
            carouselViewport.value.style.scrollBehavior = "smooth";
            return;
        }
        carouselViewport.value.scrollLeft = next;
    }
}, 5000);

const storeMode = useCookie("mode");
</script>

<template>
<div class="bg-brand-beige fixed z-50 w-full top-bar" id="top-bar">
    <div class="container hidden xl:flex justify-between p-0 h-[33px]">
        <div class="w-[168px]"></div>
        <ul class="my-0 p-0 font-sans flex text-xs justify-center">
            <li class="list-none flex items-center">
                <ClientOnly>
                    <TrustpilotWidget
                        :config="{
                            width: '305px',
                            templateId: '5419b732fbfb950b10de65e5',
                        }"
                    />
                </ClientOnly>
            </li>
            <li class="list-none mx-4">
                <span class="navbar-text me-5 flex items-center h-full">
                    <span class="ml-2">
                        <NuxtLink href="/fragt-og-levering/">
                            <span v-if="cartTotal >= 599">Du har fået <span class="font-semibold">fri fragt</span></span>
                            <span v-else-if="cartTotal >= 299">Køb for {{formatPriceDKK(599 - cartTotal)}} mere og få <span class="font-semibold">fri fragt</span></span>
                            <span v-else-if="cartTotal > 0 && cartTotal < 299">Køb for {{formatPriceDKK(299 - cartTotal)}} mere og få billigere fragt | <span class="font-semibold">Fri fragt</span> over 599,-</span>
                            <span v-else>Fragt fra 29 kr. <span class="font-semibold">Fri fragt</span> over 599 kr.</span>
                        </NuxtLink>
                    </span>
                </span>
            </li>
            <li class="list-none">
                <span class="navbar-text me-5 flex items-center h-full min-w-68">
                    <span class="ml-2" v-if="[6,0].includes(new Date().getDay())">Vi sender alle hverdage indtil kl. 21:00</span>
                    <span class="ml-2" v-else-if="new Date().getDay() == 5 && new Date().getHours() < 12">Bestil inden kl. 17:00, og vi sender i dag.</span>
                    <span class="ml-2" v-else-if="new Date().getDay() != 5 && new Date().getHours() < 17">Bestil inden kl. 21:00, og vi sender i dag.</span>
                    <span class="ml-2" v-else-if="countdown != ''">Vi sender i dag. Bestil inden <b>{{ countdown }}</b></span>
                    <span class="ml-2" v-else-if="new Date().getDay() == 5">Bestil inden kl. 17:00, og vi sender i dag.</span>
                    <span class="ml-2" v-else>Vi sender samme dag inden kl. 21:00</span>
                </span>
            </li>
        </ul>
        <ClientOnly>
            <div class="w-[168px] flex text-sm text-center">
                <a
                    href="/?mode=privat"
                    class="block py-[6px] w-[84px]"
                    :class="{'bg-white font-semibold': !storeMode || storeMode == 'privat'}">
                    <span>{{ $t('account.private') }}</span>
                </a>
                <a
                    href="/erhvervskonto/?mode=erhverv"
                    class="block py-[6px] w-[84px]"
                    :class="{'bg-white font-semibold': storeMode == 'erhverv'}">
                    <span>{{ $t('account.business') }}</span>
                </a>
            </div>
        </ClientOnly>
    </div>

    <div class="xl:hidden container carousel" id="topline-slider">
        <div class="carousel__viewport font-sans text-xs h-[25px]" ref="carouselViewport">
            <div class="carousel__slide active" ref="topBarSlide">
                <div class="text-center h-full">
                    <span class="navbar-text flex items-center justify-center h-full">
                        <span>
                            <NuxtLink href="/fragt-og-levering/">
                                <span v-if="cartTotal >= 599">Du har fået <span class="font-semibold">fri fragt</span></span>
                                <span v-else-if="cartTotal >= 299">Køb for {{formatPriceDKK(599 - cartTotal)}} mere og få <span class="font-semibold">fri fragt</span></span>
                                <span v-else-if="cartTotal > 0">Køb for {{formatPriceDKK(299 - cartTotal)}} mere og få billigere fragt</span>
                                <span v-else>Fragt fra 29,- | Fri fragt over 599,-</span>
                            </NuxtLink>
                        </span>
                    </span>
                </div>
            </div>
            <div class="carousel__slide">
                <div class="text-center h-full">
                    <span class="navbar-text flex h-full items-center justify-center">
                        <span v-if="[6,0].includes(new Date().getDay())">Vi sender alle hverdage indtil kl. 21:00</span>
                        <span v-else-if="new Date().getDay() == 5 && new Date().getHours() < 12">Bestil inden kl. 17:00, og vi sender i dag.</span>
                        <span v-else-if="new Date().getDay() != 5 && new Date().getHours() < 17">Bestil inden kl. 21:00, og vi sender i dag.</span>
                        <span v-else-if="countdown != ''">Vi sender i dag. Bestil inden <b>{{ countdown }}</b></span>
                        <span v-else-if="new Date().getDay() == 5">Bestil inden kl. 17:00, og vi sender i dag.</span>
                        <span v-else>Vi sender samme dag inden kl. 21:00</span>
                    </span>
                </div>
            </div>
            <div class="carousel__slide">
                <div class="text-center">
                    <ClientOnly>
                        <TrustpilotWidget
                            :config="{
                                height: '20px',
                                width: '475px',
                                templateId: '5419b6ffb0d04a076446a9af',
                            }"
                            class="justify-center items-center flex"
                        />
                    </ClientOnly>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style>
.top-bar .trustpilot-widget iframe {
    transform: scale(0.75);
}
</style>
